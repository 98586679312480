import styled from "styled-components";
import { Container } from "../../styles/UI";

export const MessageSent = styled.div`
  text-align: center;

  svg {
    color: ${(p) => p.theme.secondary};
  }

  h4 {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.text};
    margin-bottom: 16px;
    margin-top: 32px;
  }
  
  p {
    font-weight: 350;
    font-size: 18px; 
    line-height: 28px;
    color: ${(p) => p.theme.textDarkerGray};
    margin-bottom: 16px;
  }
`;

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: -100px;
          top: -580px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

export const Contato = styled.section`
  padding: 88px 0;
  background: ${(p) => p.theme.bgGray};

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    ${Container} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 64px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 32px;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      font-size: 35px;
      line-height: 42px;
    }
  }

  .info {
    margin-top: 44px;

    .section {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 72px;
        border-radius: 72px;
        background: ${(p) => p.theme.laranja};
        margin-right: 32px;
        flex-shrink: 0;
      }

      p.label {
        font-size: 16px;
        line-height: 20px;
        color: #534e4e;
      }

      p.dado {
        font-size: 20px;
        line-height: 28px;
        color: #534e4e;
      }

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        p.dado {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
`;

export const Faq = styled.section`
  padding: 80px 0;
  background: ${(p) => p.theme.branco};

  h5.title {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 32px;
  }

  p.duvidas {
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    color: ${(p) => p.theme.textDarkerGray};
    margin-top: 40px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 80px 0;

    p.duvidas {
      margin-top: 86px;

      div {
        margin-left: 16px;
      }
    }
  }
`;
