import { graphql } from "gatsby";
import React from "react";
import Contato from "../pagesFiles/contato";

export default function planos({ data, location }) {
  return <Contato questions={data.allFaqJson.nodes[0].produtos} 
  message={location.state} />;
}

export const query = graphql`
  {
    allFaqJson {
      nodes {
        produtos {
          body
          id
          question
        }
      }
    }
  }
`;
